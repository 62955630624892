<!-- <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <div class="col-sm-11">
      <h3 class="head">CONFIRMATION</h3>
    </div>
    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        [mat-dialog-close]="true"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <p class="head">
    {{message}}
  </p>
  <div class="text-right">
    <button class="review_btn" mat-button mat-dialog-close="cancel">YES</button>
    <button class="cancel_btn" mat-button [mat-dialog-close]="" cdkFocusInitial>
      NO
    </button>
  </div> -->
  <div class="row" id="popupContainer" #popupContainer style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <div class="col-sm-11 movable">
      <h3
      class="head"
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
     
    >
      CONFIGURE PARAMETER
    </h3>
  
    </div>
    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        mat-dialog-close="cancel"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <div class="successMessage alert-dismissible">
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        (click)="removeMessage()"
        aria-label="Close"
      ></button>
    </div>
  
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  
  <mat-tab-group (selectedTabChange)="onTabChange($event)">
    <mat-tab label="COST" style="height: 100%">
      <div class="container-fullheight">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-12" style="margin-top: 10px">
              <!-- <label for="username">Username:</label>
              <input id="username" formControlName="username" /> -->
              <div class="drpchildctrl" style="width: 50%">
                <span class="v1"></span>
                <label class="searchLable">COST PER KILO</label>
                <input
                  class="key"
                  type="text"
                  autoWidth="false"
                  maxlength="25"
                  formControlName="cost_per_kilo"
                  placeholder="--Type--"
                  (input)="onInputChange($event)"
                />
                <div
                  *ngIf="
                    userForm.get('cost_per_kilo').touched &&
                    userForm.get('cost_per_kilo').invalid
                  "
                >
                  <small
                    class="error-text"
                    *ngIf="userForm.get('cost_per_kilo').hasError('required')"
                  >
                    Cost per kilo is required.
                  </small>
                </div>
  
                <!-- <ng-container *ngIf="userForm.username.errors && submitted">
                  <small *ngIf="userForm.policyName.errors?.required">
                    Policy Name is required.
                  </small>
                </ng-container> -->
              </div>
            </div>
  
            <div class="col-12" style="margin-top: 10px">
              <!-- <label for="username">Username:</label>
                <input id="username" formControlName="username" /> -->
              <div class="drpchildctrl" style="width: 50%">
                <span class="v1"></span>
                <label class="searchLable">COST PER CUBIC</label>
                <input
                  class="key"
                  type="text"
                  autoWidth="false"
                  maxlength="25"
                  formControlName="cost_per_cubic"
                  placeholder="--Type--"
                  
                  (input)="onInputChange($event)"
                />
                <div
                  *ngIf="
                    userForm.get('cost_per_cubic').touched &&
                    userForm.get('cost_per_cubic').invalid
                  "
                >
                  <small
                    class="error-text"
                    *ngIf="userForm.get('cost_per_cubic').hasError('required')"
                  >
                    Cost per cubic is required.
                  </small>
                </div>
  
                <!-- <ng-container *ngIf="userForm.username.errors && submitted">
                    <small *ngIf="userForm.policyName.errors?.required">
                      Policy Name is required.
                    </small>
                  </ng-container> -->
              </div>
            </div>
          </div>
        </form>
        <div class="text-right" >
          <button class="review_btn" mat-button (click)="addAirCost()">
            Save
          </button>
  
          <button class="cancel_btn" mat-button mat-dialog-close="cancel">
            Cancel
          </button>
        </div>
      </div>
    </mat-tab>
  
    <mat-tab label="BUDGET CODES">
      <div class="container-fullheight">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-12" style="margin-top: 10px">
              <div class="drpchildctrl" style="width: 50%">
                <span class="v1"></span>
                <label class="searchLable">BUDGET CODES</label>
                <ul class="list-group scrollable-list">
                  <!-- Header Row for Budget Code, Default, and Action Columns -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center header"
                  >
                    <!-- Budget Code Header -->
                    <div class="col-budget-code" style="width: 150px">
                      <strong>Budget Code</strong>
                    </div>
  
                    <!-- Default Header -->
                    <div class="col-default">
                      <strong>Default</strong>
                    </div>
  
                    <!-- Action Header -->
                    <div class="col-action">
                      <strong>Action</strong>
                    </div>
                  </li>
  
                  <!-- Loop through each budget code -->
                  <li
                    *ngFor="let budget of budgetCodes"
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <!-- Budget Code Column with Fixed Width -->
                    <div class="col-budget-code" style="width: 150px">
                      <a>{{ budget.budget_code }}</a>
                      <span class="badge badge-primary badge-pill">{{
                        budget.budget_code
                      }}</span>
                    </div>
  
                    <!-- Default Column (Radio Button) -->
                    <div
                      class="col-default d-flex justify-content-center align-items-center"
                    >
                      <input
                        type="radio"
                        
                        [value]="budget.budget_code"
                        formControlName="budget_code1"
                        [checked]="budget.is_default"
                        (click)="onBudgetCodeSelect(budget.budget_code)"
                      />
                    </div>
  
                    <!-- Action Column (Delete Button) -->
                    <div
                      class="col-action d-flex justify-content-center align-items-center"
                    >
                      <img
                        class="detailiconhome"
                        src="assets/images/icon-delete.svg"
                        title="delete"
                        alt="Delete"
                        (click)="deleteBudgetCode(budget)"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="row">
                <!-- New Budget Code Input -->
                <div class="col-6" >
                  <div class="drpchildctrl" style="width: 100%">
                    <span class="v1"></span>
                    <label class="searchLable">NEW BUDGET CODE</label>
                    <input
                      class="key"
                      type="text"
                      maxlength="5"
                      formControlName="budget_code"
                      placeholder="--Type--"
                    />
                    <div
                      *ngIf="
                        userForm.get('budget_code').touched &&
                        userForm.get('budget_code').invalid
                      "
                      class="error-message"
                    >
                      <small
                        class="error-text"
                        *ngIf="userForm.get('budget_code').hasError('required')"
                        >Budget Code is required.</small
                      >
                      <!-- <small
                        *ngIf="userForm.get('budget_code').hasError('maxlength')"
                        >Budget Code cannot be longer than 5 characters.</small
                      > -->
                    </div>
                  </div>
                  
                  
                </div>
  
                <!-- Description Input -->
                <div class="col-6" >
                  <div class="drpchildctrl" style="width: 100%">
                    <label class="searchLable">DESCRIPTION</label>
                    <input
                      class="key"
                      type="text"
                      maxlength="50"
                      formControlName="budget_desc"
                      placeholder="--Type--"
                    />
                    <!-- <div
                      *ngIf="
                        userForm.get('budget_code').touched &&
                        userForm.get('budget_code').invalid
                      "
                      class="error-message"
                    >
                      <small
                        class="error-text"
                        *ngIf="userForm.get('budget_code').hasError('required')"
                        >Budget Code is required.</small
                      >
                       <small
                        *ngIf="userForm.get('budget_code').hasError('maxlength')"
                        >Budget Code cannot be longer than 25 characters.</small
                      > -->
                    <!-- </div>  -->
                  </div>
                </div>
  
                <div class="col-12" style="margin-bottom: 7px;">
                  <div class="text-right" >
                    <button
                      class="review_btn bugetCode_btn"
                      mat-button
                      (click)="addbudgetCode()"
                    >
                      Add
                    </button>
                    <button
                      class="cancel_btn bugetCode_btn"
                      mat-button
                      mat-dialog-close="cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>
  
    <mat-tab label="AIR CODE" style="height: 100%">
      <div class="container-fullheight">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="row">
  
            <div class="col-12" style="margin-top: 10px">
              <!-- <label for="username">Username:</label>
              <input id="username" formControlName="username" /> -->
  
              <div class="drpchildctrl" style="width: 50%">
                <span class="v1"></span>
                <label class="searchLable">DOCK</label>
                <p-dropdown
                  [filter]="true"
                  [virtualScroll]="true"
                  [itemSize]="30"
                  [style]="{ width: '100%' }"
                  scrollHeight="180px"
                  [options]="airCodeDockData"
                  optionLabel="dock"
                  placeholder="Select"
                  formControlName="dockNo"
                  (onChange)="onDockChange('data')"
                  filterBy="dock"
                ></p-dropdown>
  
                <label class="searchLable">AIR CODES</label>
                <ul class="list-group scrollable-list">
                  <!-- Header Row for Budget Code, Default, and Action Columns -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center header"
                  >
                    <!-- Budget Code Header -->
                    <div class="col-budget-code" style="width: 150px">
                      <strong>Air Code</strong>
                    </div>
  
                    <!-- Default Header -->
                    <div class="col-default">
                      <strong>Default</strong>
                    </div>
  
                    <!-- Action Header -->
                    <div class="col-action">
                      <strong>Action</strong>
                    </div>
                  </li>
  
                  <!-- Loop through each budget code -->
                  <li
                    *ngFor="let budget of selectedDockData"
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <!-- Budget Code Column with Fixed Width -->
                    <div class="col-budget-code" style="width: 150px">
                      <a>{{ budget.air_case_code }}</a>
                      <span class="badge badge-primary badge-pill">{{
                        budget.air_case_code
                      }}</span>
                    </div>
  
                    <!-- Default Column (Radio Button) -->
                    <div
                      class="col-default d-flex justify-content-center align-items-center"
                    >
                      <input
                        type="radio"
                        [value]="budget.air_case_code"
                        formControlName="air_case_code1"
                        [checked]="budget.is_default"
                        (click)="onAircodeSelect(budget.air_case_code)"
                      />
                    </div>
  
                    <!-- Action Column (Delete Button) -->
                    <div
                      class="col-action d-flex justify-content-center align-items-center"
                    >
                      <img
                        class="detailiconhome"
                        src="assets/images/icon-delete.svg"
                        title="delete"
                        alt="Delete"
                        (click)="deleteAirCode(budget)"
                      />
                    </div>
                  </li>
                </ul>
  
                <!-- <ng-container *ngIf="userForm.username.errors && submitted">
                  <small *ngIf="userForm.policyName.errors?.required">
                    Policy Name is required.
                  </small>
                </ng-container> -->
              </div>
            </div>
  
            <div class="card">
              <div class="row">
  
  
            <div class="col-6" style="margin-top: 10px">
    
              <!-- <label for="username">Username:</label>
              <input id="username" formControlName="username" /> -->
  
              <div class="drpchildctrl" style="width: 100%">
                <span class="v1"></span>
                <label class="searchLable">AIR CODE</label>
                <input
                  class="key"
                  type="text"
                  autoWidth="false"
                  maxlength="1"
                  formControlName="airCode"
                  placeholder="--Type--"
                  (keydown)="removeSpace($event)"
                  
                />
  
                <!-- <ng-container *ngIf="userForm.username.errors && submitted">
                  <small *ngIf="userForm.policyName.errors?.required">
                    Policy Name is required.
                  </small>
                </ng-container> -->
              </div>
            </div>
  
            <div class="col-6" style="margin-top: 20px">
              <div class="text-right" >
                <button class="review_btn" mat-button (click)="addAirCasecode()">
                  Add
                </button>
  
                <button class="cancel_btn" mat-button mat-dialog-close="cancel">
                  Cancel
                </button>
              </div>
            </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>
  
    <mat-tab label="AIRFREIGHT CONTACTS">
      <div class="container-fullheight">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-12" style="margin-top: 10px">
              <div class="drpchildctrl" style="width: 60%">
                <span class="v1"></span>
                <label class="searchLable">CONTACTS</label>
                <ul class="list-group contact-list">
                  <!-- Use *ngFor to loop through the contactData array -->
                  <li class="list-group-item d-flex justify-content-between align-items-center header">
                    <!-- Budget Code Header -->
                    <div class="col-budget-code" style="flex: 1; padding: 0 10px;">
                      <strong>Contact Name</strong>
                    </div>
              
                    <!-- Default Header (Role) -->
                    <div class="col-role" style="flex: 1; text-align: center; padding: 0 10px;">
                      <strong>Role</strong>
                    </div>
              
                    <!-- Action Header -->
                    <div class="col-action" style="width: 100px; padding: 0 10px;">
                      <strong>Action</strong>
                    </div>
                  </li>
              
                  <li *ngFor="let contact of contactData" class="list-group-item d-flex justify-content-between align-items-center">
                    <!-- Contact Name Column -->
                    <div class="col-budget-code" style="flex: 1; padding: 0 10px;">
                      <a>{{ contact.contact_name }}</a>
                    </div>
              
                    <!-- Role Column (Centered) -->
                    <div class="col-role" style="flex: 1; text-align: center; padding: 0 10px;">
                      <span>{{ contact.contact_role }}</span>
                    </div>
              
                    <!-- Action Column (Delete Icon) -->
                    <div class="col-action" style="width: 100px; padding: 0 10px;">
                      <img
                        class="detailiconhome"
                        src="assets/images/icon-delete.svg"
                        title="delete"
                        alt="Delete"
                        (click)="deleteContact(contact)"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              
            </div>
            <div class="card">
              <div class="row">
  
            <div class="col-6" style="margin-top: 10px">
              <div class="drpchildctrl" style="width: 100%">
                <span class="v1"></span>
                <label class="searchLable">CONTACT NAME</label>
                <input
                  class="key"
                  type="text"
                  maxlength="25"
                  formControlName="contact_name"
                  placeholder="--Type--"
                />
              </div>
            </div>
            <div class="col-6" style="margin-top: 10px">
            <div class="drpchildctrl" style="width: 100%">
              <span class="v1"></span>
              <label class="searchLable">ROLE</label>
              <p-dropdown
                    id="selSupplier"
                    [filter]="true"
                    [virtualScroll]="true"
                    [itemSize]="25"
                    [showTransitionOptions]="'0ms'"
                    placeholder="Select"
                    [resetFilterOnHide]="true"
                    [hideTransitionOptions]="'0ms'"
                    autoWidth="true"
                    [style]="{ width: '100%', height: '27px' }"
                    appendTo="body"
                    scrollHeight="100px"
                    formControlName="contact_role"
                    [options]="roles"
                    filterBy="contact_role"
                  >
                <!-- Binding the roles array directly -->
  
                <ng-template pTemplate="item" let-item>
                  <div>{{ item }}</div>
                  <!-- Display the option value -->
                </ng-template>
  
                <ng-template pTemplate="selectedItem" let-selectedItem>
                  <div>{{ selectedItem }}</div>
                  <!-- Display selected value -->
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          </div>
  
          <div class="text-right" >
            <button class="review_btn" mat-button (click)="addContact()">
              Add
            </button>
            <button class="cancel_btn" mat-button mat-dialog-close="cancel">
              Cancel
            </button>
          </div>
            </div>
            </div>
  
        </form>
      </div>
    </mat-tab>
  
    <mat-tab label="RECIEVING ETA TIME">
      <div class="container-fullheight">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-6">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label for="timeSelect" class="searchLable">TIME</label>
                <div>
                  <select formControlName="fromHoursHH" id="fromHoursHH">
                    <option value="" disabled selected>Hour</option>
                    <option *ngFor="let a of hoursArray; let index = index" [value]="index">
                      {{ formatNumber(index) }}
                    </option>
                  </select>
                  :
                  <select formControlName="fromMintuesMM" id="fromMintuesMM">
                    <option value="" disabled selected>Minute</option>
                    <option *ngFor="let a of mintuesArray; let index = index" [value]="index">
                      {{ formatNumber(index) }}
                    </option>
                  </select>
                </div>
                <div>
                  <!-- <ng-container *ngIf="addOrEdit.fromHoursHH.errors && submitted">
                    <small *ngIf="addOrEdit.fromHoursHH.errors?.required">
                      From Time (hour) is required.
                    </small>
                  </ng-container>
                  <ng-container *ngIf="addOrEdit.fromMintuesMM.errors && submitted">
                    <small *ngIf="addOrEdit.fromMintuesMM.errors?.required">
                      From Time (minute) is required.
                    </small>
                  </ng-container> -->
                </div>
              </div>
            </div>
            <!-- <div class="col-12" style="margin-top: 10px">
              <div class="drpchildctrl" style="width: 50%">
                <span class="v1"></span>
                <label class="searchLable">TIME</label>
                <input
                  class="key"
                  type="time"
                  maxlength="25"
                  formControlName="receivingEtaDate"
                  placeholder="--Type--"
                />
              </div>
            </div> -->
          </div>
        </form>
  
        <div class="text-right" >
          <button class="review_btn" mat-button (click)="updateETAtime()">
            Save
          </button>
          <button class="cancel_btn" mat-button mat-dialog-close="cancel">
            Cancel
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>