import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-airorder-delete',
  templateUrl: './airorder-delete.component.html',
  styleUrls: ['./airorder-delete.component.scss']
})
export class AirorderDeleteComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data,) { }
  rowdata: any = [];
  renban: any = [];
  renbans = '';
  mesaage: string;


  ngOnInit(): void {
    console.log("data",this.data,this.data[0].isSchedule)
    console.log("inside popup")
    this.mesaage = `Are you sure you want to delete this air order - ${this.data[0].air_order_no}`;
    if (this.data[0]?.isSchedule) {
      this.mesaage = "Are you sure you want to delete the schedule"

    }
    if (this.data[1]?.isSchedule) {
      this.mesaage = "Are you sure you want to delete the schedule"

    }

    if (this.data[0]?.isParts) {
      this.mesaage = "Are you sure you want to delete the Part"

    }
    if (this.data[0]?.isContact) {
      this.mesaage = "Are you sure you want to delete the contact"

    }
    if (this.data[0]?.isDeleteBudgetCode) {
      this.mesaage = "Are you sure you want to delete the Budget Code"

    }
    if (this.data[0]?.isDeleteAirCaseCode) {
      this.mesaage = "Are you sure you want to delete the Air Code"

    }


    


  }






}




